<template>
  <div class="Investment_cont">
     <!-- 办公区租赁 -->
    <el-card class="lease">
      <div class="lease_title">
        <h2>办公区租赁</h2>
      </div>
      <el-form :model="get_office_area" ref="officeRef" label-width="120px" size="small" class="leaseFrom">
        <el-form-item label="办公配套面积:" prop="office_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="get_office_area.office_area" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="办公总房间数:" prop="office_room" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange" v-model.number="get_office_area.office_room" autocomplete="off"></el-input>
          <span> 间</span>
        </el-form-item>
        <el-form-item label="车位总数:" prop="car_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input  type="number" @change="officeChange" v-model.number="get_office_area.car_num" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <h3>办公面积出租情况</h3>
        <el-form-item label="已出租面积:" prop="leasehold_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange" v-model.number="get_office_area.leasehold_area" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="未出租面积:" prop="unletable_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number"  @change="officeChange"  v-model.number="get_office_area.unletable_area" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="出租率:" prop="area_rate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input  type="number" v-model.number="calculation_area_rate" autocomplete="off" :disabled="true"></el-input>
          <span> %</span>
        </el-form-item>
        <h3>车位出租情况</h3>
        <el-form-item label="已出租:" prop="car_leasehold" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange" v-model.number="get_office_area.car_leasehold" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="未出租:" prop="car_not_rent" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" v-model.number="calculation_car_not_rent" autocomplete="off" :disabled="true"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="出租率:" prop="car_rate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input  type="number" v-model.number="calculation_car_rate" autocomplete="off" :disabled="true"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
    </el-card>
     <!-- 办公区租赁明细 -->
    <el-card class="detailed">
      <div class="detailed_title">
        <h2>办公租赁明细</h2>
      </div>
      <div class="detailed_box">
        <div class="detailed_box_title">
          <span>楼层</span><span>已出租</span><span>未出租</span><span>出租率</span>
        </div>
        <el-form  v-for="(item, index) in office_detail" :key="index" :model="item" size="small" ref="detailedRef">
          <div class="detailed_items">
            <el-form-item class="item">
              <el-input v-model="item.floor" disabled></el-input>
            </el-form-item>
            <!-- <el-form-item class="item" prop="all_room" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
              <el-input type="number" @input="detailedChange(item.all_room,item.rent,item.id)" v-model.number="item.all_room"></el-input>
            </el-form-item> -->
            <el-form-item class="item" prop="rent" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
              <el-input type="number" @input="detailedChange(item.not_rent,item.rent,item.id)" v-model.number="item.rent"></el-input>
            </el-form-item>
            <el-form-item class="item" prop="not_rent" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
              <el-input type="number" @input="detailedChange(item.not_rent,item.rent,item.id)" v-model.number="item.not_rent"></el-input>
            </el-form-item>
            <el-form-item class="item">
              <el-input v-model="item.rent_rate" disabled></el-input> %
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>

    <!-- 园区企业 -->
    <el-card class="enterprise facilities">
      <div class="enterprise_title">
        <h2>园区企业</h2>
      </div>
      <el-button type="primary" class="facilities_btn" @click="dialogVisible4 = true">新增园区企业</el-button>
      <el-table :data="joining_company" border style="width: 80%" class="facilities_table">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <div v-text="(queryInfo.jc_page - 1) * 10 + scope.$index + 1"></div>
          </template>
        </el-table-column>
        <el-table-column prop="image" label="封面" align="center">
          <template slot-scope="scope">
            <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template>
            企业
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名称" align="center"></el-table-column>
        <el-table-column prop="profile" label="企业简介" align="center"></el-table-column>
        <el-table-column prop="order_num" label="排序" align="center">
          <template slot-scope="scope">
            {{ scope.row.order_num ? scope.row.order_num : 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" align="center">
          <template slot-scope="scope">
            {{new Date(scope.row.created_at).getFullYear() + '-' + (new Date(scope.row.created_at).getMonth()+1) + '-' + new Date(scope.row.created_at).getDate()}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showEditDialog4(scope.row.id)">编辑</el-button>
            <el-button type="text"  @click="removeJoiningCompany(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @current-change="jc_page_change"
        :current-page="queryInfo.jc_page"
        :total="queryInfo.jc_total">
      </el-pagination>
    </el-card>
    <el-dialog class="attract_cont" title="新增园区企业" :visible.sync="dialogVisible4" :closeOnClickModal="false" :center="true" width="50%">
      <!-- 内容主体区 -->
      <el-form id='addFormRef4' ref="addFormRef4" :model="joining_company_data" :rules="addRules4"  label-width="120px">
        <el-form-item label="封面：" prop="image">
          <el-upload
            class="avatar-uploader"
            action=""
            accept="image/jpeg,image/gif,image/png"
            name="image"
            :on-change="onUploadChange4"
            :auto-upload="false"
            :show-file-list="false">
            <img v-if="joining_company_data.image" :src="joining_company_data.image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="企业名称:" prop="name">
          <el-input placeholder="输入企业名称" name='name' v-model="joining_company_data.name"></el-input>
        </el-form-item>
        <el-form-item label="企业简介:" prop="profile">
          <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="joining_company_data.profile"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="order_num">
          <el-input placeholder="输入排序号" name='order_num' v-model="joining_company_data.order_num"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible4 = false">取 消</el-button>
              <el-button type="primary" @click="addJoiningCompany">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog class="attract_cont" title="编辑园区企业" :visible.sync="dialogVisibleEdit4" :closeOnClickModal="false" :center="true" width="50%">
      <el-form id='addFormRefEdit4' :model="get_joining_company" :rules="addRules4"  label-width="120px">
        <el-form-item label="封面：" prop="image">
          <el-upload
            class="avatar-uploader"
            action=""
            accept="image/jpeg,image/gif,image/png"
            name="image"
            :on-change="onUploadChangeEdit4"
            :auto-upload="false"
            :show-file-list="false">
            <img v-if="get_joining_company.image" :src="get_joining_company.image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="企业名称:" prop="name">
          <el-input placeholder="输入企业名称" name='name' v-model="get_joining_company.name"></el-input>
        </el-form-item>
        <el-form-item label="企业简介:" prop="profile">
          <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="get_joining_company.profile"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="order_num">
          <el-input placeholder="输入排序号" name='order_num' v-model="get_joining_company.order_num"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEdit4 = false">取 消</el-button>
        <el-button type="primary" @click="editJoiningCompany">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      serverAddress: 'https://res.bjstarfish.com/',
      joining_company: [],
      dialogVisible4: false,
      dialogVisibleEdit4: false,
      joining_company_data: {
        image: '',
        name: '',
        profile: ''
      },
      get_joining_company: {
        image: '',
        name: '',
        profile: ''
      },
      addRules4: {
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入商户简介', trigger: 'blur' }
        ]
      },
      // 办公区租赁的数据
      office_area: {},
      // 获取办公区租赁的数据
      get_office_area: {},
      // 办公租赁明细的数据
      office_detail: {},
      // 获取办公租赁明细的数据
      get_office_detail: {},
      // 表格的数据
      tableData: [],
      // 添加按钮的显示
      dialogVisible: false,
      // 编辑按钮的显示
      dialogVisibleEdit: false,
      // 添加企业表单的数据
      add_company: {
        floor: '',
        name: '',
        address: '',
        introduce: ''
      },
      // 获取当前企业表单的数据
      get_company_data: {},
      // 对话框的规则
      companyRules: {
        floor: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        introduce: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      },
      // 园区企业的分页
      queryInfo: {
        // 当前的页数
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10,
        jc_page: 1,
        jc_total: 0
      }
    }
  },
  async created () {
    this.get_office()
    this.getOfficeData()
    this.getJoiningCompanyList()
  },
  computed: {
    // 未出租面积 办公配套面积 - 已出租面积
    // calculation_unletable_area () {
    //   return this.get_office_area.office_area - this.get_office_area.leasehold_area
    // },
    // 出租率 已出租面积 / 办公配套面积
    calculation_area_rate () {
      return Math.round(this.get_office_area.leasehold_area / (this.get_office_area.leasehold_area + this.get_office_area.unletable_area) * 100)
    },
    // 未出租 车位总数 - 已出租
    calculation_car_not_rent () {
      return this.get_office_area.car_num - this.get_office_area.car_leasehold
    },
    // 出租率 已出租 / 车位总数
    calculation_car_rate () {
      return Math.round(this.get_office_area.car_leasehold / this.get_office_area.car_num * 100)
    }
    // 园区企业的分页的table数据
    // AfterChangeData () {
    //   const start = (this.queryInfo.pageNum - 1) * this.queryInfo.pageSize
    //   const end = this.queryInfo.pageNum * this.queryInfo.pageSize
    //   return this.tableData.slice(start, end)
    // }
  },
  methods: {
    // 获取办公区租赁的数据
    async get_office () {
      const { data: res } = await this.$http.get('office_area')
      this.office_area[0] = res.data
      for (const key in this.office_area[0][0]) {
        this.office_area[0][0][key] = Number(this.office_area[0][0][key])
      }
      this.get_office_area = this.office_area[0][0]
      // console.log(this.get_office_area)
    },
    // 修改租赁的情况
    officeChange () {
      this.$refs.officeRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post(`office_area/${this.get_office_area.id}`, {
          office_area: this.get_office_area.office_area,
          office_room: this.get_office_area.office_room,
          car_num: this.get_office_area.car_num,
          leasehold_area: this.get_office_area.leasehold_area,
          unletable_area: this.get_office_area.unletable_area,
          car_leasehold: this.get_office_area.car_leasehold,
          _method: 'put'
        })
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
        this.get_office()
      })
    },
    // 获取办公租赁明细的数据
    async getOfficeData () {
      const { data: res } = await this.$http.get('office_detail')
      res.data.map(item => {
        item.rent = Number(item.rent)
        item.not_rent = Number(item.not_rent)
      })
      this.office_detail = res.data
    },
    // 修改办公租赁明细
    async detailedChange (num1, num2, id) {
      // this.$refs.detailedRef.validate(async valid => {
      //   if (!valid) return false
      if (num1 === '' || num2 === '') return 0
      const { data: res } = await this.$http.post(`office_detail/${id}`, {
        rent: num2,
        not_rent: num1,
        _method: 'put'
      })
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getOfficeData()
      // })
    },
    onUploadChangeEdit4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_joining_company.image = e.currentTarget.result
      }
    },
    async getJoiningCompanyList () {
      const { data: res } = await this.$http.get(`rh_joining_company?limit=10&page=${this.queryInfo.jc_page}`)
      this.joining_company = res.data
      this.queryInfo.jc_page = res.pagination.page
      this.queryInfo.jc_total = res.pagination.all_count
    },
    jc_page_change (newPage) {
      this.queryInfo.jc_page = newPage
      this.getJoiningCompanyList()
    },
    addJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRef4'))
      this.$refs.addFormRef4.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('rh_joining_company', data)
        this.dialogVisible4 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningCompanyList()
      })
    },
    async editJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRefEdit4'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`rh_joining_company/${this.get_joining_company.id}`, data)
      this.dialogVisibleEdit4 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async removeJoiningCompany (id) {
      const { data: res } = await this.$http.delete(`rh_joining_company/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async showEditDialog4 (id) {
      this.dialogVisibleEdit4 = true
      const { data: res } = await this.$http.get(`rh_joining_company/${id}`)
      this.get_joining_company = res.data
      this.get_joining_company.image = this.serverAddress + res.data.image
    },
    onUploadChange4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.image = e.currentTarget.result
      }
    }
  }
}
</script>

<style lang="less" scoped>
.facilities_btn{
  margin: 1rem;
}
.park,.enterprise{
  margin-bottom: 1rem;
  .park_title,.enterprise_title{
    position: relative;
    h2{
      padding-left: 0.7rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
    ::after{
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 3px;
      height: 20px;
      background-color: #000;
    }
  }
  .rowClass{
    flex-direction: column;
  }
  .el-row{
    display: flex;
    width: 100%;
  }
  .parkFrom,.enterpriseFrom{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    .el-form-item{
      width: 20%;
      .el-input{
        width: 75%;
      }
    }
  }
}
.save_btn{
  display: flex;
  justify-content: center;
  .el-button--primary{
    background-color: #015478;
    border-color: #015478;
    padding: 12px 60px;
  }
}
.Investment_cont{
  padding: 1rem;
  .lease{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 33%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
        }
      }
    }
  }
  .detailed{
    margin-bottom: 1rem;
    .detailed_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .detailed_box{
      width: 50%;
      display: flex;
      flex-direction: column;
      .detailed_box_title{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        span{
          width: 20%;
          text-align: center;
        }
      }
      .detailed_items{
        display: flex;
        justify-content: space-between;
        // margin-bottom: 1rem;
        text-align: center;
        .item{
          width: 20%;
          display: flex;
          justify-content: center;
          .el-input{
            width: 80%;
            .el-input--small .el-input__inner{
              text-align: center;
            }
          }
        }
      }
    }
  }
  .Industry{
    .Industry_title_btn{
      display: flex;
      align-items: center;
      .el-button{
        width: 7%;
        padding: 8px;
        font-size: 14px;
        border-radius: 5px;
        align-self: flex-start;
        margin-left: 2rem;
      }
    }
    .Industry_table{
      margin: 1rem 0;
    }
  }
  .dialogIndustry{
    .el-form{
      width: 80%;
      .el-select{
        width: 100%;
      }
    }
  }
}
</style>
